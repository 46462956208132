import React from "react";

export const Timeline = () => {
  return (
    <div
      id="timeline"
      className="bg-gray-200 mx-auto w-full h-full rounded-md py-16"
    >
      <div className="z-20 flex justify-center items-center order-1">
        <h1 className="uppercase my-4 text-xl text-center md:text-2xl">
          Experience
        </h1>
      </div>
      <div className="relative wrap overflow-hidden p-10 h-full md:w-9/12 m-auto">
        <div className="absolute h-full border"></div>
        {/* <!-- left timeline --> */}
        <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg max-w-4">
              -
            </h1>
          </div>
          <div className="order-1 bg-red-700 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">Cadu</h3>
            <h5 className="mb-3 font-bold text-white text-md">Fall 2020</h5>
            <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
              Software Engineer Freelancer building with React and Redux
            </p>
            <div className="flex">
              <a
                className="py-2 mt-8 w-1/2 md:w-1/4 bg-main hover:bg-white hover:text-black text-white text-center rounded-md"
                href="http://cadunow.com"
              >
                Go
              </a>
            </div>
          </div>
        </div>
        {/* <!-- right timeline --> */}
        <div className="mb-8 flex justify-between items-center w-full right-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg">-</h1>
          </div>
          <div className="order-1 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-gray-800 text-xl">Curtn Inc.</h3>
            <h5 className="mb-3 font-bold text-gray-800 text-md">
              Summer 2019
            </h5>
            <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              Created the administrative panel for their mobile application
            </p>
            <div className="flex">
              <a
                className="py-2 mt-8 w-1/2 md:w-1/4 bg-red-700 hover:bg-black text-white text-center rounded-md"
                href="https://www.curtn.com/"
              >
                Go
              </a>
            </div>
          </div>
        </div>
        {/* <!-- left timeline --> */}
        <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg">-</h1>
          </div>
          <div className="order-1 bg-red-700 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">
              Fordham University
            </h3>
            <h5 className="mb-3 font-bold text-white text-md">2019-2020</h5>
            <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
              M.S. Computer Science - Class of 2020
            </p>
          </div>
        </div>
        {/* <!-- right timeline --> */}
        <div className="mb-8 flex justify-between items-center w-full right-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg">-</h1>
          </div>
          <div className="order-1 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-gray-800 text-xl">
              General Assembly
            </h3>
            <h5 className="mb-3 font-bold text-gray-800 text-md">
              Winter & Spring 2018
            </h5>
            <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              Web Development Immersive Graduate
            </p>
            <div className="flex">
              <a
                className="py-2 mt-8 w-1/2 md:w-1/4 bg-red-700 hover:bg-black text-white text-center rounded-md"
                href="https://generalassemb.ly/education/software-engineering-immersive/new-york-city?&topic=&mkt_account_id=1056949875&mkt_campaign_id=1593758872&mkt_ad_group_id=60511284872&mkt_device_type=c&mkt_keyword=+web%20+development%20+general%20+assembly&mkt_matchtype=b&mkt_placement=&mkt_ad_id=417694281520&mkt_network=g&mkt_target_id=kwd-536587512899&mkt_feed_item_id=&utm_source=google&utm_medium=paid-search-bra&utm_campaign=TS:TX:BRA:NYC:BRTP:COD:WebDev:GeneralAssembly&utm_content=PDP-FT&utm_term=+web%20+development%20+general%20+assembly&gclid=CjwKCAjw3pWDBhB3EiwAV1c5rJ_W_w_S9lBfdbUY9lmK2_b_4pABi-_ks5U6usnRNLtSvgpiUfKg9RoCKyAQAvD_BwE"
              >
                Go
              </a>
            </div>
          </div>
        </div>
        {/* <!-- left timeline --> */}
        <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg">-</h1>
          </div>
          <div className="order-1 bg-red-700 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-white text-xl">KPMG</h3>
            <h5 className="mb-3 font-bold text-white text-md">2014-2017</h5>
            <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
              Associate / Senior Associate - Alternative Investments Tax
            </p>
          </div>
        </div>
        {/* <!-- right timeline --> */}
        <div className="mb-8 flex justify-between items-center w-full right-timeline">
          <div className="order-1 md:w-5/12"></div>
          <div className="z-20 flex items-center order-1 bg-white shadow-lg md:w-8 md:h-8 m-4 md:rounded-md">
            <h1 className="mx-auto text-white font-semibold text-lg">-</h1>
          </div>
          <div className="order-1 rounded-md shadow-lg md:w-5/12 px-6 py-4">
            <h3 className="mb-3 font-bold text-gray-800 text-xl">
              Syracuse University
            </h3>
            <h5 className="mb-3 font-bold text-gray-800 text-md">2010-2014</h5>
            <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              B.S. Information Management and Technology / Accounting - Class of
              2014
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Timeline;
