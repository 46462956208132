import React from "react";

export const About = () => {
  return (
    <div
      id="about"
      className="h-screen flex flex-wrap w-screen flex-col items-center justify-center text-center py-8 wallpaper"
    >
      {/* <h1 className=" title-font mb-12 text-xl font-extrabold leading-10 tracking-tight text-center text-white sm:text-3xl sm:leading-none md:text-4xl">
        {" "}
        Welcome!
      </h1> */}
      <h1 className="title-font mb-12 text-xl font-extrabold leading-10 tracking-tight text-center text-white sm:text-xl sm:leading-none md:text-2xl">
        {" "}
        My name is Matteson
      </h1>
      <p className=" title-font mb-2 text-md px-8 font-extrabold leading-10 tracking-tight text-center text-white sm:leading-none md:text-lg">
        {" "}
        I'm a Full-Stack Software Engineer, looking to build a better future.
        CPA in a past life. Huge baseball nerd.
      </p>
      <button
        className={`py-2 mx-auto mt-8 w-5/12 md:w-12% bg-main text-white hover:bg-white hover:text-black rounded-md focus:outline-none`}
        onClick={() => {
          window.scrollTo(0, document.body.scrollHeight);
        }}
      >
        Contact Me
      </button>
    </div>
  );
};

export default About;
