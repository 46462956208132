import React from "react";
import y_invest from "../Assets/Y-Invest.png";
import mtg_baseball from "../Assets/MTG-Baseball.png";
import calendar from "../Assets/Calendar.png";
import to_do from "../Assets/To-Do-Home.png";
import MERNG from "../Assets/MERNG.png";
import ruby_legos from "../Assets/Ruby-Legos.png";

export const Projects = () => {
  return (
    <>
      <h1 className="uppercase my-16 text-xl text-center md:text-2xl">
        Projects
      </h1>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${MERNG})`,
          }}
          title="MERNG"
        ></div>
        {/* <div
          className="hover:opacity-25 h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${MERNG})`,
          }}
          onClick={() => (window.location.href = "https://udbt.vercel.app/")}
          title="MERNG"
        ></div> */}
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            Prospect Central
          </h3>
          <p className="text-black text-center text-xs py-4">
            Dynasty Baseball Team Hub
          </p>
          <p className="text-black text-center text-sm">
            Keep track of your dynasty baseball prospect lists, watchlists, and
            team. Built with secure authentication / authorization and MERNG
            stack.
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://udbt.vercel.app/"
            >
              Go
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${to_do})`,
          }}
          title="To-Do"
        ></div>
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            To-Do
          </h3>
          <p className="text-black text-center text-xs py-4">
            React Native To-Do App
          </p>
          <p className="text-black text-center text-sm">
            This application allows users to keep track of their to-do items and
            has secure authentication / authorization. Built with React Native
            with Redux and Firebase.
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://github.com/mattesongrossman/RNR-Firebase"
            >
              Go
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${y_invest})`,
          }}
          title="Y-Invest"
        ></div>
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            Y-Invest
          </h3>
          <p className="text-black text-center text-xs py-4">
            Stock and Cryptocurrency Portfolio App
          </p>
          <p className="text-black text-center text-sm">
            This application allows users to keep track of their current
            investments with real time pricing and secure authentication /
            authorization. Built with React on Rails.
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://github.com/mattesongrossman/Y-Invest"
            >
              Go
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${ruby_legos})`,
          }}
          title="Ruby-Legos"
        ></div>
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            Ruby Legos
          </h3>
          <p className="text-black text-center text-xs py-4">Lego Board App</p>
          <p className="text-black text-center text-sm">
            Built fully in Ruby. Final project created for my Fordham Object
            Oriented Design class (Spring 2019)
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://github.com/mattesongrossman/Ruby-Legos"
            >
              Go
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${mtg_baseball})`,
          }}
          title="MTG-Baseball"
        ></div>
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            MTG-Baseball
          </h3>
          <p className="text-black text-center text-xs py-4">
            Fantasy Baseball Drafting Tool
          </p>
          <p className="text-black text-center text-sm">
            For avid fantasy baseball owners. This application has been built
            with JavaScript with EJS on the front end with Node and PostgreSQL
            powering the back end.
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://github.com/mattesongrossman/MTG-Baseball"
            >
              Go
            </a>
          </div>
        </div>
      </div>
      <div className="px-6 pb-16 md:px-8 md:w-7/12 lg:flex m-auto">
        <div
          className="h-64 md:h-80 lg:w-1/2 flex-none bg-cover bg-no-repeat rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${calendar})`,
          }}
          title="MTG-Baseball"
        ></div>
        <div className="grid-cols-1 gap-4 p-2 md:px-8 m-auto">
          <h3 className="uppercase text-black font-bold text-xl pt-4 text-center">
            Calendar
          </h3>
          <p className="text-black text-center text-xs py-4">
            React Calender App
          </p>
          <p className="text-black text-center text-sm">
            A full CRUD application allowing users to track, create, edit and
            delete events on their calendar. PERN Stack - Built with Postgres,
            Express, React, and Node.
          </p>
          <div className="flex">
            <a
              className="py-2 mt-8 w-1/2 md:w-1/3 m-auto bg-main text-white border-1 border-main hover:bg-white hover:text-black text-center rounded-md"
              href="https://github.com/mattesongrossman/Calendar_App"
            >
              Go
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
