import React, { useState } from "react";
// import { useForm } from "@formspree/react";

export const Contact = () => {
  let [emailVal, setEmail] = useState("");
  let [message, setMessage] = useState("");

  // let [invalid] = useState(false);

  // const submitEmail = () => {
  //     emailjs.send(
  //         service_id,
  //         'contact_form',
  //         data,
  //         user_id
  //     ).then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // }

  // const validEmail = (email: string) => {
  //     let valid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email.toLowerCase())
  //     if (valid) {
  //         return true
  //     }
  //     return false
  // }
  return (
    <div
      id="contact"
      className="w-full max-w-xs sm:max-w-sm md:max-w-lg m-auto my-16 rounded-lg p-4"
    >
      <div className="flex justify-center text-white md:overflow-visible md:whitespace-no-wrap items-center mt-4 text-2xl md:text-left ">
        Lets Talk!
      </div>
      <form
        className="pt-2 my-2"
        action="https://formspree.io/f/xjvjbdrg"
        method="POST"
      >
        <div className={`max-w-xs pb-4 mx-auto`}>
          {/* <label className="block text-left text-white text-md font-semibold mb-2">
            Email
          </label> */}
          <input
            name="emailVal"
            placeholder="Email"
            className={`bg-red-700 placeholder-white shadow appearance-none border-1 border-white rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline`}
            id="username"
            type="text"
            value={emailVal}
            onChange={(evt) => setEmail(evt.target.value)}
          />
          {/* <p className="hidden text-red-500 text-xs italic">Please enter an email.</p> */}
          {/* <div className={`${(invalid) ? 'visible' : 'hidden'}`}>
                            <p className="mt-2 text-sm text-red-600" id="email-error">Invalid email, please re-enter</p>
                        </div> */}
        </div>
        <div className={`max-w-xs pb-4 mx-auto text-white`}>
          {/* <label className="block text-left text-md text-white font-semibold my-1">
            Message
          </label> */}
          <textarea
            name="message"
            placeholder="Message"
            className={`bg-red-700 active:outline-none placeholder-white shadow appearance-none border-1 border-white rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline`}
            id="message"
            value={message}
            rows={8}
            onChange={(evt) => setMessage(evt.target.value)}
          />
        </div>
        <div className="flex">
          <button
            type="submit"
            className={`py-2 mx-auto mt-8 w-1/2 md:w-1/3 bg-main hover:bg-white hover:text-black text-white rounded-md`}
          >
            Send Email
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
