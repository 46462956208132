// import NavBar from "./Components/NavBar";
import Contact from "./Components/Contact";
import Projects from "./Components/Projects";
import About from "./Components/About";
import Timeline from "./Components/Timeline";
import Footer from "./Components/Footer";
import "./index.css";
import "./App.css";

function App() {
  return (
    // Background photo by Henry & Co. from Pexels
    <div className="flex flex-col w-screen h-full justify-center items-center">
      <div className="flex-1 overflow-y-scroll w-screen h-screen">
        <About />
        <div className="main">
          <Timeline />
          <Projects />
        </div>
      </div>
      <div className="waves w-full mt-16">
        <svg width="100%" height="200px" fill="none">
          <path
            fill="#c53030"
            d="M 0 67 C 273,183 822,-40 1920,106 V 359 H 0 V 67 Z"
          ></path>
        </svg>
      </div>
      <div className="w-full bg-red-700 h-full">
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
